.home-section {
    width: 100%;
    height: 92dvh;
    padding-top: 8dvh;
    display: flex;
    background-image: url("../../../assets/images/homeBG.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: fixed;
    background-color: black;
    overflow: hidden;
}

.home-ls {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.home-title {
    color: white;
    height: 35%;
    margin-top: 5%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 50%;
}

.home-title-text {
    font-size: max(4rem, 5dvh);
    width: 90%;
    font-family: "Pursuit";
    letter-spacing: -5px;
    text-shadow:
        -1px -1px 0 #152238,
        1px -1px 0 #152238,
        -1px 1px 0 #152238,
        1px 1px 0 #152238;
}

.home-txt {
    width: 50%;
    margin-top: 2.5%;
    height: 40%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 2.5%;
}

.home-txt-content {
    width: 90%;
    font-size: 25px;
    font-family: "Bebas";
    letter-spacing: 1px;
    text-shadow:
        -1px -1px 0 #152238,
        1px -1px 0 #152238,
        -1px 1px 0 #152238,
        1px 1px 0 #152238;
}

.home-btn {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 10%;
    margin-bottom: 5%;
    width: 50%;

    /* a {
        padding: 12.5px 30px;
        border: 0;
        border-radius: 100px;
        background-color: white;
        color: #152238;
        font-weight: Bold;
        transition: all 0.2s;
        -webkit-transition: all 0.2s;
        font-family: "Bebas";
        font-size: 30px;
    }

    a:hover {
        background-color: #152238;
        box-shadow: 0 0 20px #6fc5ff50;
        transform: scale(1.1);
        color: white;
    } */
}

@media screen and (max-width: 1300px) {

    .home-txt {
        width: 70%;
    }

    .home-txt-content {
        text-align: center;
    }

    .home-title-text {
        font-size: 7dvh;
    }
}

@media screen and (max-width: 1000px) {
    .home-txt {
        width: 90%;
        margin-bottom: 0;
        margin-top: 0;
    }

    .home-txt-content {
        font-size: max(20px, 2.7dvh);
    }

    .home-title {
        width: 90%;
    }

    .home-title-text {
        width: 100%;
        text-align: center;
    }

    .home-btn {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .home-txt {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2.5%;
        margin-bottom: 2.5%;
    }

    .home-txt-content {
        font-size: 2dvh;
        text-align: center;
    }

    .home-title {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        height: 25%;
    }

    .home-title-text {
        font-size: 5dvh;
    }

    .home-btn {
        /* width: 100%;

        a {
            font-size: 3dvh;
        } */
    }
}