.about-section {
    width: 100%;
    height: 92dvh;
    padding-top: 8dvh;
    position: absolute;
    top: 300dvh;
    left: 0;
    background-color: #152238;
}

.leaflet-map {
    width: 100%;
    height: 100%;
}

.about-body {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.map-cont {
    width: 90%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leaflet-map {
    width: 90%;
    height: 90%;
}

.building-img {
    position: absolute;
    bottom: 25px;
    right: 25px;
    width: auto;
    height: 50%;
    z-index: 500;
    border-radius: 1000px;
    border: 2px solid #152238;
}

.leaflet-control-container {
    position: absolute;
    z-index: 400 !important;
}

.about-footer {
    width: 100%;
    height: 20%;
    display: flex;
    border-top: 2px solid white;
    box-sizing: border-box;
}

.about-footer-img {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-logo-img {
    width: 60%;
    height: auto;
}

.about-footer-txt {
    width: 16.6%;
    padding-left: 2.5%;
    padding-right: 2.5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: white;
    font-family: "Bebas";
    letter-spacing: 1px;
    justify-content: center;
}

.footer-txt-title {
    margin-top: 10px;
    font-size: 2.6dvh;
    letter-spacing: 2px;
}

.footer-txt-content {
    margin-top: 5px;
    font-size: 2.1dvh;
    text-decoration: none !important;
}

.about-footer-socials {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.footer-social-link {
    width: auto;
    height: 50%;
}

.footer-social-img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
}

.copy-right {
    position: absolute;
    color: white;
    bottom: 0px;
    left: 0px;
    width: 100%;
}

.copy-right-txt {
    width: 100%;
    font-size: 10px;
    text-align: center;
}

@media screen and (max-width: 1000px) {
    .about-section {
        width: 100%;
        height: 102dvh;
        margin-top: -8dvh;
        padding-top: 8dvh;
        position: absolute;
        top: 474dvh;
        left: 0;
        background-color: #152238;
        display: flex;
        flex-direction: column;
    }

    .about-body {
        width: 100%;
        height: 35%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .about-footer {
        width: 100%;
        height: 65%;
        display: flex;
        border-top: 2px solid white;
        box-sizing: border-box;
        flex-direction: column;
    }

    .map-cont {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .about-footer-img {
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;
    }

    .footer-logo-img {
        width: auto;
        height: 80%
    }

    .about-footer-txt {
        width: 100%;
        height: 25%;
        display: flex;
        flex-direction: column;
        color: white;
        font-family: "Bebas";
        letter-spacing: 1px;
        justify-content: center;
        padding: 0;
    }

    .footer-txt-title {
        margin-top: 10px;
        font-size: 28px;
        letter-spacing: 2px;
        width: 100%;
        text-align: center;
    }

    .footer-txt-content {
        margin-top: 5px;
        font-size: 2dvh;
        width: 100%;
        text-align: center;
    }

    .about-footer-socials {
        width: 100%;
        height: 20%;
        display: flex;
    }

    .footer-social-link {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .footer-social-img {
        width: 40%;
        height: auto;
    }

    .building-img {
        position: absolute;
        bottom: 5px !important;
        right: 5px !important;
        width: auto;
        height: 40% !important;
        z-index: 500;
        border-radius: 1000px;
        border: 2px solid #152238;
    }
}