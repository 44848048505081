.facility-section {
    position: absolute;
    width: 100%;
    top: 100dvh;
    left: 0;
    height: 92dvh;
    padding-top: 8dvh;
    background-color: #152238;
}

.facility-body {
    width: 100%;
    height: 60%;
    display: flex;
}


.facility-body-ls {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.facility-body-ls-img {
    width: 80%;
    height: 80%;
    object-fit: cover !important;
    box-shadow: 30px 30px 20px rgba(0, 0, 0, 0.5);
}

.facility-body-rs {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: "Bebas";
    display: flex;
    flex-direction: column;
}

.facility-body-rs-title {
    margin-top: 10%;
    height: 10%;
    width: 90%;
    font-size: 55px;
    margin-bottom: 5%;
    font-family: "pursuit";
}

.facility-body-rs-desc {
    font-size: 2.5dvh !important;
    width: 90%;
    height: 75%;
    letter-spacing: 1px;
}

.facility-footer {
    width: 100%;
    height: 35%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.facility-footer-img {
    width: 25%;
    height: 80%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease;
}

.facility-footer-img-title {
    position: absolute !important;
    z-index: 99 !important;
    font-family: "pursuit" !important;
    font-size: 40px !important;
    color: white !important;
    cursor: pointer !important;
    -webkit-text-stroke: 1px #152238 !important;
    opacity: 0;
    transition: all 0.1s ease;
}

.footer-img {
    width: 100% !important;
    min-height: 100% !important;
    object-fit: cover !important;
    cursor: pointer;
    transition: all 0.1s ease;
}

.facility-footer-img:hover {
    transition: all 0.1s ease;
    box-shadow: 30px 30px 20px rgba(0, 0, 0, 0.5);
    transform: scale(1.05);

    .facility-footer-img-title {
        position: absolute !important;
        z-index: 99 !important;
        font-family: "pursuit" !important;
        font-size: 40px !important;
        color: white !important;
        cursor: pointer !important;
        -webkit-text-stroke: 1px #152238 !important;
        opacity: 1;
        transition: all 0.1s ease;
    }

    .footer-img {
        width: 100% !important;
        min-height: 100% !important;
        object-fit: cover !important;
        cursor: pointer;
        transition: all 0.1s ease;
        filter: blur(3px);
    }
}

.mobile-facility-cont {
    display: none;
}

@media screen and (max-width: 1300px) {
    .facility-footer-img-title {
        font-size: 3vw !important;
    }
}

@media screen and (max-width: 1000px) {

    .facility-body,
    .facility-footer {
        display: none;
    }

    .facility-section {
        height: 280dvh;
        padding-top: 0;
        margin-top: -8dvh;
    }

    .mobile-facility-cont {
        padding-top: 8dvh;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-evenly;
        color: white;
        height: 100%;
    }

    .mobile-facility-feature {
        width: 100%;
        height: 33%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .mobile-facility-feature-title {
        height: 10dvh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 8vw;
        font-family: "Pursuit";
    }

    .mobile-facility-feature-img {
        width: 90%;
        height: 30%;
        object-fit: cover !important;
        box-shadow: 30px 30px 20px rgba(0, 0, 0, 0.5);
        margin-bottom: 5%;
    }

    .mobile-facility-feature-desc {
        font-size: 2.5dvh;
        width: 90%;
        height: 40%;
        letter-spacing: 1px;
        font-family: "bebas";
        width: 90%;
        text-align: center;
    }
}